.landing-page-revised {
    background-color: #030014;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .landing-page-revised .div {
    background-color: #030014;
    height: 10850px;
    overflow: hidden;
    position: relative;
    width: 1440px;
  }
  
  .landing-page-revised .overlap {
    height: 856px;
    left: 34px;
    position: absolute;
    top: 84px;
    width: 1532px;
  }
  
  .landing-page-revised .overlap-group {
    height: 856px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1532px;
  }
  
  .landing-page-revised .hero-BG {
    height: 856px;
    left: 545px;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 987px;
  }
  
  .landing-page-revised .overlap-group-2 {
    height: 856px;
    position: relative;
  }
  
  .landing-page-revised .ellipse {
    border: 1px solid;
    border-color: #4ba5eb;
    border-radius: 364px;
    height: 728px;
    left: 183px;
    opacity: 0.25;
    position: absolute;
    top: 23px;
    width: 728px;
  }
  
  .landing-page-revised .ellipse-2 {
    border: 1px solid;
    border-color: #4ba5eb;
    border-radius: 364px/305px;
    height: 610px;
    left: 87px;
    opacity: 0.25;
    position: absolute;
    top: 112px;
    width: 728px;
  }
  
  .landing-page-revised .ellipse-3 {
    border: 1px solid;
    border-color: #4ba5eb;
    border-radius: 364px/279.23px;
    height: 558px;
    left: 77px;
    opacity: 0.25;
    position: absolute;
    top: 115px;
    transform: rotate(21.71deg);
    width: 728px;
  }
  
  .landing-page-revised .ellipse-4 {
    background: radial-gradient(50% 50% at 50% 50%, rgb(75, 165, 235) 0%, rgba(75, 165, 235, 0) 100%);
    border-radius: 469px/428px;
    height: 856px;
    left: 49px;
    opacity: 0.71;
    position: absolute;
    top: 0;
    width: 938px;
  }
  
  .landing-page-revised .small-icons-in-hero {
    height: 577px;
    left: 686px;
    position: absolute;
    top: 112px;
    width: 648px;
  }
  
  .landing-page-revised .group {
    -webkit-backdrop-filter: blur(800px) brightness(100%);
    backdrop-filter: blur(800px) brightness(100%);
    background-color: #0bb9ec99;
    border-radius: 71px;
    height: 60px;
    left: 94px;
    position: absolute;
    top: 4px;
    width: 60px;
  }
  
  .landing-page-revised .icon-instance-node {
    height: 32px !important;
    left: 14px !important;
    position: absolute !important;
    top: 14px !important;
    width: 32px !important;
  }
  
  .landing-page-revised .brackets-curly-wrapper {
    -webkit-backdrop-filter: blur(800px) brightness(100%);
    backdrop-filter: blur(800px) brightness(100%);
    background-color: #ebd18999;
    border-radius: 41px;
    height: 60px;
    left: 588px;
    position: absolute;
    top: 517px;
    width: 60px;
  }
  
  .landing-page-revised .tree-structure-wrapper {
    -webkit-backdrop-filter: blur(800px) brightness(100%);
    backdrop-filter: blur(800px) brightness(100%);
    background-color: #c5795899;
    border-radius: 68px;
    height: 60px;
    left: 583px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .landing-page-revised .cpu-wrapper {
    -webkit-backdrop-filter: blur(800px) brightness(100%);
    backdrop-filter: blur(800px) brightness(100%);
    background-color: #5c74c699;
    border-radius: 60px;
    height: 60px;
    left: 0;
    position: absolute;
    top: 286px;
    width: 60px;
  }
  
  .landing-page-revised .frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 620px;
    left: 0;
    position: absolute;
    top: 58px;
    width: 627px;
  }
  
  .landing-page-revised .frame-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 33px;
    height: 565px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .code-genie-AI-audits {
    flex: 0 0 auto;
    position: relative;
    width: 344.9px;
  }
  
  .landing-page-revised .text-wrapper {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    text-align: center;
    width: 506px;
  }
  
  .landing-page-revised .p {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: -34px;
    position: relative;
    text-align: justify;
    width: 506px;
  }
  
  .landing-page-revised .frame-3 {
    align-items: center;
    background: linear-gradient(180deg, rgb(75, 165, 235) 0%, rgb(1, 104, 181) 100%);
    border-radius: 4px;
    display: flex;
    gap: 8px;
    height: 56px;
    justify-content: center;
    padding: 12px 16px;
    position: relative;
    width: 332px;
    margin-top: 10px;
  }
  
  .landing-page-revised .text-wrapper-2 {
    color: #ffffff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    cursor: pointer;
  }
  
  .landing-page-revised .icon-instance-node-2 {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }
  
  .landing-page-revised .layer {
    height: 638px;
    left: 818px;
    position: absolute;
    top: 68px;
    width: 434px;
  }
  
  .landing-page-revised .overlap-2 {
    height: 626px;
    position: relative;
  }
  
  .landing-page-revised .vector {
    height: 611px;
    left: 0;
    position: absolute;
    top: 0;
    width: 434px;
  }
  
  .landing-page-revised .code-wrapper {
    -webkit-backdrop-filter: blur(800px) brightness(100%);
    backdrop-filter: blur(800px) brightness(100%);
    background-color: #0bb9ec99;
    border-radius: 71px;
    height: 60px;
    left: 313px;
    position: absolute;
    top: 385px;
    width: 60px;
  }
  
  .landing-page-revised .cpu-1-wrapper {
    -webkit-backdrop-filter: blur(800px) brightness(100%);
    backdrop-filter: blur(800px) brightness(100%);
    background-color: #53399d99;
    border-radius: 60px;
    height: 60px;
    left: 0;
    position: absolute;
    top: 566px;
    width: 60px;
  }
  
  .landing-page-revised .frame-4 {
    align-items: flex-start;
    background-color: #010522;
    border: 1px solid;
    border-color: #1d252d;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    left: 171px;
    padding: 16px 12px;
    position: absolute;
    top: 720px;
  }
  
  .landing-page-revised .text-wrapper-3 {
    color: #e2e2e5;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.45px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }
  
  .landing-page-revised .img {
    height: 44px;
    position: relative;
    width: 44px;
  }
  
  .landing-page-revised .frame-6 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-4 {
    color: #e2e2e5;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .text-wrapper-5 {
    color: #7a7a7a;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .overlap-3 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .landing-page-revised .nav-bar {
    align-items: center;
    display: flex;
    gap: 651px;
    height: 80px;
    left: 0;
    padding: 16px 32px;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .landing-page-revised .frame-7 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 96px;
    position: relative;
  }
  
  .landing-page-revised .frame-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    cursor: pointer;
  }
  
  .landing-page-revised .codegenie-logo {
    height: 44px;
    object-fit: cover;
    position: relative;
    width: 44px;
  }
  
  .landing-page-revised .logo {
    color: #e2e2e5;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.44px;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 40px;
    position: relative;
  }
  
  .landing-page-revised .frame-10 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-6 {
    color: #4ba5eb;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .text-wrapper-7 {
    color: #e2e2e5;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .div-wrapper {
    align-items: center;
    background: linear-gradient(180deg, rgb(75, 165, 235) 0%, rgb(1, 104, 181) 100%);
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 12px 16px;
    position: relative;
    cursor: pointer;
  }
  
  .landing-page-revised .text-wrapper-8 {
    color: #ffffff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .frame-11 {
    align-items: center;
    border: 1px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(1, 104, 181) 41.15%, rgb(75, 165, 235)) 1;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 12px 16px;
    position: relative;
    cursor: pointer;
  }
  
  .landing-page-revised .text-wrapper-9 {
    color: #4ba5eb;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .hero-nav-devider {
    background-color: #293034;
    height: 1px;
    left: 0;
    position: absolute;
    top: 79px;
    transform: rotate(180deg);
    width: 1440px;
  }
  
  .landing-page-revised .frame-12 {
    align-items: center;
    background-color: #030014;
    display: flex;
    gap: 58px;
    justify-content: center;
    left: 0;
    padding: 64px 133px 64px 132px;
    position: absolute;
    top: 1208px;
    width: 1441px;
  }
  
  .landing-page-revised .volume-isometric-wrapper {
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, rgb(0, 17.27, 138.13) 0%, rgba(0, 3, 25, 0) 100%);
    border-radius: 194px;
    display: flex;
    gap: 10px;
    height: 300px;
    justify-content: center;
    padding: 32px 72px;
    position: relative;
    width: 300px;
  }
  
  .landing-page-revised .volume-isometric {
    height: 191px;
    position: relative;
    width: 87.96px;
  }
  
  .landing-page-revised .frame-13 {
    align-items: flex-start;
    background-color: #090619;
    border: 1px solid;
    border-color: #475569;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 40px 53px;
    position: relative;
    width: 806px;
  }
  
  .landing-page-revised .text-wrapper-10 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    align-self: stretch;
    background: linear-gradient(180deg, rgb(1, 104, 181) 0%, rgb(75, 165, 235) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-fill-color: transparent;
  }
  
  .landing-page-revised .frame-14 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    margin-right: -13px;
    position: relative;
    width: 713px;
  }
  
  .landing-page-revised .ellipse-5 {
    background-color: #ffffff;
    border-radius: 4px;
    height: 8px;
    position: relative;
    width: 8px;
  }
  
  .landing-page-revised .text-wrapper-11 {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    cursor: default;
  }
  
  .landing-page-revised .frame-15 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .text-wrapper-12 {
    color: #efedfd;
    flex: 1;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
  }
  
  .landing-page-revised .frame-16 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 56px;
    left: 0;
    padding: 96px 32px;
    position: absolute;
    top: 1636px;
  }
  
  .landing-page-revised .text-wrapper-13 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(1, 104, 181) 0%, rgb(75, 165, 235) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    text-fill-color: transparent;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .frame-17 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
  }
  
  .landing-page-revised .frame-18 {
    align-items: flex-start;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 32px;
    position: relative;
    width: 448px;
  }
  
  .landing-page-revised .frame-19 {
    align-items: center;
    background-color: #0b0e24;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(37, 47, 71) 21.35%, rgba(37, 47, 71, 0.32)) 1;
    border-radius: 96px;
    display: flex;
    gap: 10px;
    height: 60px;
    justify-content: center;
    padding: 24px 32px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .icon-instance-node-3 {
    height: 32px !important;
    margin-bottom: -10px !important;
    margin-left: -18px !important;
    margin-right: -18px !important;
    margin-top: -10px !important;
    position: relative !important;
    width: 32px !important;
  }
  
  .landing-page-revised .frame-20 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .text-wrapper-14 {
    align-self: stretch;
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.6px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-15 {
    align-self: stretch;
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
  }
  
  .landing-page-revised .frame-21 {
    align-items: flex-start;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 442px;
    padding: 24px 32px;
    position: relative;
    width: 448px;
  }
  
  .landing-page-revised .overlap-4 {
    height: 1685px;
    left: -84px;
    position: absolute;
    top: 2336px;
    width: 1904px;
  }
  
  .landing-page-revised .USP-section {
    height: 1005px;
    left: 84px;
    position: absolute;
    top: 0;
    width: 1820px;
  }
  
  .landing-page-revised .overlap-5 {
    background-color: #030014;
    height: 1005px;
    position: relative;
    width: 1441px;
  }
  
  .landing-page-revised .frame-22 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 56px;
    left: 148px;
    position: absolute;
    top: 99px;
  }
  
  .landing-page-revised .frame-23 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }
  
  .landing-page-revised .rectangle {
    background: linear-gradient(180deg, rgb(75, 165, 235) 0%, rgba(75, 165, 235, 0) 100%);
    height: 1px;
    position: relative;
    width: 580px;
  }
  
  .landing-page-revised .frame-24 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
  
  .landing-page-revised .group-2 {
    height: 60px;
    margin-bottom: -10px;
    margin-left: -4px;
    margin-top: -2px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .text-wrapper-16 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.6px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .text-wrapper-17 {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 168px;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 572px;
  }
  
  .landing-page-revised .text-wrapper-18 {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 87px;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 564px;
  }
  
  .landing-page-revised .terminal-window-wrapper {
    background-color: #4ba5eb;
    border-radius: 4px;
    box-shadow: 4px 4px 8px #4ba5eb1f;
    height: 44px;
    position: relative;
    width: 44px;
  }
  
  .landing-page-revised .terminal-window {
    height: 24px !important;
    left: 10px !important;
    position: absolute !important;
    top: 10px !important;
    width: 24px !important;
  }
  
  .landing-page-revised .text-wrapper-19 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.6px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    width: 395px;
  }
  
  .landing-page-revised .ultimately-by-using {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 115px;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 564px;
  }
  
  .landing-page-revised .overlap-group-3 {
    height: 948px;
    left: 837px;
    position: absolute;
    top: 3px;
    width: 603px;
  }
  
  .landing-page-revised .ellipse-6 {
    height: 948px;
    left: 35px;
    position: absolute;
    top: 0;
    width: 568px;
  }
  
  .landing-page-revised .ellipse-7 {
    height: 838px;
    left: 90px;
    position: absolute;
    top: 55px;
    width: 513px;
  }
  
  .landing-page-revised .rectangle-2 {
    height: 644px;
    left: 0;
    position: absolute;
    top: 189px;
    width: 564px;
  }
  
  .landing-page-revised .ellipse-8 {
    background-color: #4ba5eb;
    border-radius: 4px;
    height: 8px;
    left: 336px;
    position: absolute;
    top: 88px;
    width: 8px;
  }
  
  .landing-page-revised .ellipse-9 {
    background-color: #4ba5eb;
    border-radius: 4px;
    height: 8px;
    left: 184px;
    position: absolute;
    top: 122px;
    width: 8px;
  }
  
  .landing-page-revised .ellipse-10 {
    background-color: #4ba5eb;
    border-radius: 4px;
    height: 8px;
    left: 1067px;
    position: absolute;
    top: 732px;
    width: 8px;
  }
  
  .landing-page-revised .ellipse-11 {
    background-color: #4ba5eb;
    border-radius: 4px;
    height: 8px;
    left: 1158px;
    position: absolute;
    top: 724px;
    width: 8px;
  }
  
  .landing-page-revised .ellipse-12 {
    background-color: #4ba5eb;
    border-radius: 4px;
    height: 8px;
    left: 1224px;
    position: absolute;
    top: 749px;
    width: 8px;
  }
  
  .landing-page-revised .ellipse-13 {
    background-color: #4ba5eb;
    border-radius: 4px;
    height: 8px;
    left: 1289px;
    position: absolute;
    top: 20px;
    width: 8px;
  }
  
  .landing-page-revised .features {
    height: 684px;
    left: 0;
    position: absolute;
    top: 1001px;
    width: 1668px;
  }
  
  .landing-page-revised .overlap-6 {
    height: 1043px;
    left: 84px;
    position: relative;
    top: -167px;
    width: 1441px;
  }
  
  .landing-page-revised .ellipse-14 {
    height: 490px;
    left: 1003px;
    position: absolute;
    top: 307px;
    width: 437px;
  }
  
  .landing-page-revised .ellipse-15 {
    height: 644px;
    left: 0;
    position: absolute;
    top: 0;
    width: 446px;
  }
  
  .landing-page-revised .ellipse-16 {
    height: 752px;
    left: 912px;
    position: absolute;
    top: 291px;
    width: 528px;
  }
  
  .landing-page-revised .ellipse-17 {
    height: 238px;
    left: 0;
    position: absolute;
    top: 197px;
    width: 181px;
  }
  
  .landing-page-revised .frame-25 {
    align-items: center;
    background-color: #030014;
    border: 1px solid;
    border-color: transparent;
    border-image: linear-gradient(
        to bottom,
        rgba(75, 165, 235, 0.32),
        rgba(75, 165, 235, 0) 48.96%,
        rgba(75, 165, 235, 0.32) 100%
      )
      1;
    display: flex;
    flex-direction: column;
    gap: 56px;
    left: 0;
    padding: 96px 137px 96px 146px;
    position: absolute;
    top: 167px;
    width: 1441px;
  }
  
  .landing-page-revised .text-wrapper-20 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(1, 104, 181) 0%, rgb(75, 165, 235) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    text-fill-color: transparent;
    width: 902px;
  }
  
  .landing-page-revised .frame-26 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 115px;
    position: relative;
    width: 1143px;
  }
  
  .landing-page-revised .frame-27 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    position: relative;
  }
  
  .landing-page-revised .frame-28 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .bracketscurly-2-wrapper {
    background-image: url(../assets/img/ellipse-27-2.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .icon-instance-node-4 {
    height: 24px !important;
    left: 18px !important;
    position: absolute !important;
    top: 18px !important;
    width: 24px !important;
  }
  
  .landing-page-revised .text-wrapper-21 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: normal;
    position: relative;
    width: 216px;
  }
  
  .landing-page-revised .treestructure-2-wrapper {
    background-image: url(../assets/img/ellipse-16.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .text-wrapper-22 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: normal;
    position: relative;
    width: 250px;
  }
  
  .landing-page-revised .gradient-wrapper {
    background-image: url(../assets/img/ellipse-17.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .text-wrapper-23 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: normal;
    position: relative;
    width: 239px;
  }
  
  .landing-page-revised .eye-wrapper {
    background-image: url(../assets/img/ellipse-28.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .text-wrapper-24 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 216px;
  }
  
  .landing-page-revised .circle-wavy-wrapper {
    background-image: url(../assets/img/ellipse-29.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .text-wrapper-25 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 264px;
  }
  
  .landing-page-revised .user-gear-wrapper {
    background-image: url(../assets/img/ellipse-30-1.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .user-gear {
    height: 24px !important;
    left: 20px !important;
    position: absolute !important;
    top: 18px !important;
    width: 24px !important;
  }
  
  .landing-page-revised .text-wrapper-26 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: normal;
    position: relative;
    width: 264px;
  }
  
  .landing-page-revised .frame-29 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    margin-right: -87px;
    position: relative;
  }
  
  .landing-page-revised .git-pull-request-wrapper {
    background-image: url(../assets/img/ellipse-25-1.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .text-wrapper-27 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: normal;
    position: relative;
    width: 164px;
  }
  
  .landing-page-revised .code-3-wrapper {
    background-image: url(../assets/img/ellipse-30.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .text-wrapper-28 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: normal;
    position: relative;
    width: 258px;
  }
  
  .landing-page-revised .bug-beetle-wrapper {
    background-image: url(../assets/img/ellipse-27-1.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .frame-30 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 56px;
    left: 0;
    padding: 96px 32px;
    position: absolute;
    top: 5105px;
    width: 1440px;
  }
  
  .landing-page-revised .frame-31 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 36px;
    position: relative;
  }
  
  .landing-page-revised .caret-right-wrapper {
    align-items: center;
    background-color: #0b0e24;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(37, 47, 71) 21.35%, rgba(37, 47, 71, 0.32)) 1;
    border-radius: 96px;
    display: flex;
    gap: 10px;
    height: 60px;
    justify-content: center;
    padding: 24px 32px;
    position: relative;
    transform: rotate(180deg);
    width: 60px;
  }
  
  .landing-page-revised .caret-right {
    height: 32px !important;
    margin-bottom: -10px !important;
    margin-left: -18px !important;
    margin-right: -18px !important;
    margin-top: -10px !important;
    position: relative !important;
    transform: rotate(-180deg) !important;
    width: 32px !important;
  }
  
  .landing-page-revised .frame-32 {
    align-items: flex-start;
    display: flex;
    gap: 16px;
    position: relative;
    width: 1145px;
  }
  
  .landing-page-revised .frame-33 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    padding: 24px 32px;
    position: relative;
  }
  
  .landing-page-revised .bracketscurly-1-wrapper {
    background-image: url(../assets/img/ellipse-27.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .frame-34 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .text-wrapper-29 {
    align-self: stretch;
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.6px;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-30 {
    align-self: stretch;
    color: #efedfd;
    flex: 1;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
  }
  
  .landing-page-revised .frame-35 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .gitpullrequest-wrapper {
    background-image: url(../assets/img/ellipse-25.svg);
    background-size: 100% 100%;
    height: 60px;
    position: relative;
    width: 60px;
  }
  
  .landing-page-revised .get-started-section {
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, rgb(0, 6.5, 54.19) 0%, rgba(0, 3, 25, 0) 100%);
    display: flex;
    gap: 248px;
    left: 0;
    padding: 96px 73px 96px 148px;
    position: absolute;
    top: 4021px;
    width: 1440px;
  }
  
  .landing-page-revised .text-wrapper-31 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    width: 564px;
  }
  
  .landing-page-revised .video-section {
    align-items: center;
    background-color: #030014;
    display: flex;
    gap: 96px;
    left: -2px;
    padding: 96px 32px 96px 148px;
    position: absolute;
    top: 4309px;
    width: 1444px;
  }
  
  .landing-page-revised .frame-36 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 484px;
  }
  
  .landing-page-revised .at-code-genie-we {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 448px;
  }
  
  .landing-page-revised .frame-37 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-32 {
    color: #4ba5eb;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .play-circle-wrapper {
    background-image: url(../assets/img/rectangle-66.svg);
    background-size: 100% 100%;
    height: 443px;
    position: relative;
    width: 683px;
  }
  
  .landing-page-revised .play-circle {
    height: 72px !important;
    left: 309px !important;
    position: absolute !important;
    top: 186px !important;
    width: 72px !important;
  }
  
  .landing-page-revised .text-wrapper-33 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(1, 104, 181) 0%, rgb(75, 165, 235) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 496px;
    letter-spacing: -0.8px;
    line-height: 48px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 6040px;
    width: 448px;
  }
  
  .landing-page-revised .frame-38 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 56px;
    left: 1px;
    padding: 96px 0px;
    position: absolute;
    top: 6933px;
    width: 1439px;
  }
  
  .landing-page-revised .text-wrapper-34 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(1, 104, 181) 0%, rgb(75, 165, 235) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    text-fill-color: transparent;
    width: 573px;
  }
  
  .landing-page-revised .frame-wrapper {
    align-items: flex-start;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 181px;
    padding: 24px 32px;
    position: relative;
    width: 565px;
  }
  
  .landing-page-revised .frame-39 {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: -2px 0px;
    left: 272px;
    position: absolute;
    top: 6193px;
    width: 894px;
  }
  
  .landing-page-revised .frame-40 {
    align-items: center;
    background: linear-gradient(180deg, rgba(75, 165, 235, 0) 0%, rgba(75, 165, 235, 0.08) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0) 61.98%, rgb(37, 47, 71)) 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 323px;
    padding: 40px 32px;
    position: relative;
    width: 448px;
  }
  
  .landing-page-revised .frame-41 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .text-wrapper-35 {
    align-self: stretch;
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.6px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .landing-page-revised .text-wrapper-36 {
    align-self: stretch;
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    text-align: center;
  }
  
  .landing-page-revised .frame-42 {
    align-items: center;
    background: linear-gradient(180deg, rgba(75, 165, 235, 0.08) 0%, rgba(75, 165, 235, 0) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0) 61.98%, rgb(37, 47, 71)) 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 323px;
    margin-left: -2px;
    padding: 40px 32px;
    position: relative;
    width: 448px;
  }
  
  .landing-page-revised .frame-43 {
    align-items: center;
    background: linear-gradient(180deg, rgba(75, 165, 235, 0.08) 0%, rgba(75, 165, 235, 0) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0) 61.98%, rgb(37, 47, 71)) 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 323px;
    left: 446px;
    padding: 24px 32px;
    position: absolute;
    top: 321px;
    width: 448px;
  }
  
  .landing-page-revised .text-wrapper-37 {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-left: -3.5px;
    margin-right: -3.5px;
    position: relative;
    text-align: center;
    width: 391px;
  }
  
  .landing-page-revised .frame-44 {
    align-items: center;
    background: linear-gradient(180deg, rgba(75, 165, 235, 0) 0%, rgba(75, 165, 235, 0.08) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0) 61.98%, rgb(37, 47, 71)) 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 323px;
    left: 0;
    padding: 24px 32px;
    position: absolute;
    top: 321px;
    width: 448px;
  }
  
  .landing-page-revised .FAQ {
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, rgb(0, 6.5, 54.19) 0%, rgba(0, 3, 25, 0) 100%);
    display: flex;
    flex-direction: column;
    gap: 132px;
    left: 1px;
    padding: 96px 73px 96px 150px;
    position: absolute;
    top: 8246px;
    width: 1439px;
  }
  
  .landing-page-revised .overlap-group-wrapper {
    height: 310px;
    margin-left: -2.33px;
    margin-right: -2.33px;
    position: relative;
    width: 1220.65px;
  }
  
  .landing-page-revised .overlap-group-4 {
    height: 310px;
    position: relative;
    width: 1221px;
  }
  
  .landing-page-revised .frame-45 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 30px;
    left: 364px;
    position: absolute;
    top: 0px;
    width: 857px;
    padding: 10px;
  }
  
  .css-1ylu0bo {
    display: flex;
    flex-direction: column;
    -moz-box-flex: 1;
    flex-grow: 1;
    margin-top: -30px;
  }
  
  .landing-page-revised .frame-46 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(75, 165, 235, 0.16), rgb(75, 165, 235)) 1;
    border-radius: 4px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    padding: 24px 32px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .frame-47 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 756px;
  }
  
  .landing-page-revised .text-wrapper-38 {
    color: #f4f0ff;
    flex: 1;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.6px;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
  }
  
  .landing-page-revised .caret-up {
    height: 24px !important;
    margin-left: -168px !important;
    position: relative !important;
    width: 24px !important;
  }
  
  .landing-page-revised .frame-48 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    padding: 24px 32px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .frame-49 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 402px;
  }
  
  .landing-page-revised .text-wrapper-39 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(1, 104, 181) 0%, rgb(75, 165, 235) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-fill-color: transparent;
    width: 385.32px;
  }
  
  .landing-page-revised .couldn-t-find-what {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    width: 329px;
  }
  
  .landing-page-revised .rectangle-3 {
    background: linear-gradient(180deg, rgba(75, 165, 235, 0) 0%, rgb(75, 165, 235) 50.52%, rgba(75, 165, 235, 0) 100%);
    height: 1px;
    left: 0;
    position: absolute;
    top: 985px;
    width: 1440px;
  }
  
  .landing-page-revised .rectangle-4 {
    background: linear-gradient(180deg, rgba(75, 165, 235, 0) 0%, rgb(75, 165, 235) 50.52%, rgba(75, 165, 235, 0) 100%);
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .landing-page-revised .TESTIMONIALS {
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(75, 165, 235, 0.24) 0%,
      rgba(182.66, 65, 224, 0.08) 28.65%,
      rgba(224, 111, 65, 0.24) 71.88%,
      rgba(75, 165, 235, 0.24) 100%
    );
    display: flex;
    flex-direction: column;
    gap: 48px;
    left: 0;
    padding: 96px 32px;
    position: absolute;
    top: 9232px;
    width: 1440px;
  }
  
  .landing-page-revised .frame-50 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }
  
  .landing-page-revised .TESTIMONIALS-wrapper {
    align-items: center;
    border: 1px solid;
    border-color: #ffffff;
    border-radius: 31px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 8px 16px;
    position: relative;
  }
  
  .landing-page-revised .TESTIMONIALS-2 {
    color: #ffffff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 35px;
    position: relative;
    text-align: center;
    width: fit-content;
    margin: 0px;
  }
  
  .landing-page-revised .span {
    font-weight: 700;
  }
  
  .landing-page-revised .text-wrapper-40 {
    font-weight: 600;
  }
  
  .landing-page-revised .group-3 {
    height: 52px;
    margin-right: -2px;
    position: relative;
    width: 556px;
  }
  
  .landing-page-revised .what-people-say {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    letter-spacing: -0.8px;
    line-height: 44px;
    position: absolute;
    text-align: center;
    top: 4px;
    width: 356px;
  }
  
  .landing-page-revised .frame-51 {
    align-items: center;
    border: 1px solid;
    border-color: #4ba5eb;
    border-radius: 4px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    left: 357px;
    padding: 4px 10px;
    position: absolute;
    top: 0;
  }
  
  .landing-page-revised .text-wrapper-41 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(1, 104, 181) 0%, rgb(75, 165, 235) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 44px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    text-fill-color: transparent;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .frame-52 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 1289px;
  }
  
  .landing-page-revised .frame-53 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 14.38px;
    position: relative;
  }
  
  .landing-page-revised .frame-54 {
    align-items: flex-start;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 14.38px;
    height: 210px;
    padding: 24px 32px;
    position: relative;
    width: 403.48px;
  }
  
  .landing-page-revised .frame-55 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .text-wrapper-42 {
    color: #f4f0ff;
    flex: 1;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14.4px;
    font-weight: 500;
    letter-spacing: -0.36px;
    line-height: 21.6px;
    margin-top: -0.9px;
    position: relative;
  }
  
  .landing-page-revised .twitter-svg {
    height: 10.78px;
    position: relative;
    width: 12.58px;
  }
  
  .landing-page-revised .rectangle-5 {
    align-self: stretch;
    background-color: #cacbcc;
    border-radius: 13.48px;
    height: 1px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .frame-56 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 14.38px;
    position: relative;
  }
  
  .landing-page-revised .ellipse-18 {
    height: 28.76px;
    position: relative;
    width: 28.76px;
  }
  
  .landing-page-revised .frame-57 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-43 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 12.6px;
    font-weight: 500;
    letter-spacing: -0.31px;
    line-height: normal;
    margin-top: -0.9px;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .text-wrapper-44 {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 12.6px;
    font-weight: 500;
    letter-spacing: -0.31px;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .frame-58 {
    align-items: flex-start;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(75, 165, 235, 0.16), rgb(75, 165, 235)) 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 260px;
    padding: 24px 32px;
    position: relative;
    width: 449px;
  }
  
  .landing-page-revised .frame-59 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 44px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .text-wrapper-45 {
    color: #f4f0ff;
    flex: 1;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
  }
  
  .landing-page-revised .twitter-svg-2 {
    height: 12px;
    position: relative;
    width: 14px;
  }
  
  .landing-page-revised .rectangle-6 {
    align-self: stretch;
    background-color: #4ba5eb;
    border-radius: 15px;
    height: 1px;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .arrow-circle-right {
    height: 32px;
    position: relative;
    width: 32px;
  }
  
  .landing-page-revised .text-wrapper-46 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .text-wrapper-47 {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .frame-60 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    justify-content: center;
    position: relative;
  }
  
  .landing-page-revised .frame-61 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 56px;
    left: 0;
    padding: 96px 32px;
    position: absolute;
    top: 9792px;
    display:block;
  }
  
  .landing-page-revised .frame-62 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-48 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(3, 106, 183) 0%, rgb(73, 164, 234) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-fill-color: transparent;
    width: fit-content;
  }
  
  .landing-page-revised .frame-63 {
    align-items: center;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 32px;
    position: relative;
    width: 448px;
  }
  
  .landing-page-revised .rectangle-7 {
    height: 294px;
    margin-left: -19px;
    margin-right: -19px;
    position: relative;
    width: 422px;
  }
  
  .landing-page-revised .frame-64 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
  }
  
  .landing-page-revised .frame-65 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-49 {
    color: #f4f0ff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
    width: 351px;
  }
  
  .landing-page-revised .text-wrapper-50 {
    color: #efedfd;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.08px;
    line-height: 28.8px;
    position: relative;
    width: 357px;
  }
  
  .landing-page-revised .frame-66 {
    align-items: center;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 8px 24px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-51 {
    color: #4ba5eb;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28.8px;
    margin-top: -2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .arrow-right {
    height: 16px !important;
    position: relative !important;
    width: 16px !important;
  }
  
  .landing-page-revised .frame-67 {
    align-items: center;
    background-color: #090619;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(37, 47, 71, 0.32), rgb(37, 47, 71) 51.77%) 1;
    border-radius: 4px;
    display: inline-flex;
    gap: 8px;
    height: 40px;
    justify-content: center;
    padding: 8px 24px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-52 {
    color: #4ba5eb;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28.8px;
    margin-bottom: -0.5px;
    margin-top: -4.5px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .frame-68 {
    align-items: center;
    border: 1px solid;
    border-color: #4ba5eb;
    border-radius: 4px;
    display: flex;
    flex: 0 0 auto;
    gap: 13px;
    justify-content: center;
    padding: 8px;
    position: relative;
    width: 216px;
  }
  
  .landing-page-revised .text-wrapper-53 {
    color: #4ba5eb;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: -1px;
    position: relative;
    width: 131px;
  }
  
  .landing-page-revised .footer {
    align-items: flex-start;
    background: radial-gradient(50% 50% at 50% 50%, rgb(0, 6.5, 54.19) 0%, rgba(0, 3, 25, 0) 100%);
    background-color: transparent;
    display: flex;
    gap: 60px;
    left: 0;
    padding: 96px 73px 96px 32px;
    position: absolute;
    top: 10656px;
    width: 1440px;
  }
  
  .landing-page-revised .frame-69 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 36px;
    position: relative;
  }
  
  .landing-page-revised .logo-2 {
    color: #ffffff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.44px;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .landing-page-revised .text-wrapper-54 {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    position: relative;
    width: 328px;
  }
  
  .landing-page-revised .text-wrapper-55 {
    color: #fcfcfc;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.04px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    width: 100px;
  }
  
  .landing-page-revised .frame-70 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-56 {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    width: 95px;
  }
  
  .landing-page-revised .text-wrapper-57 {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    position: relative;
    width: 95px;
  }
  
  .landing-page-revised .text-wrapper-58 {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    position: relative;
    width: 116px;
  }
  
  .landing-page-revised .text-wrapper-59 {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    position: relative;
    width: 161px;
  }
  
  .landing-page-revised .text-wrapper-60 {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    position: relative;
    width: 134px;
  }
  
  .landing-page-revised .text-wrapper-61 {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    width: 65px;
  }
  
  .landing-page-revised .text-wrapper-62 {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    width: 167px;
    cursor: pointer;
  }
  
  .landing-page-revised .privacy-cookie {
    color: #eeeeee;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    position: relative;
    width: 182px;
  }
  
  .landing-page-revised .frame-71 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-right: -41px;
    position: relative;
    width: 304px;
  }
  
  .landing-page-revised .text-wrapper-63 {
    align-self: stretch;
    color: #fcfcfc;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.04px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-64 {
    align-self: stretch;
    color: #fcfcfc;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    position: relative;
  }
  
  .landing-page-revised .frame-72 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
  }
  
  .landing-page-revised .frame-73 {
    align-items: center;
    background-color: #dfefff;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    padding: 8px 10px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-65 {
    color: #6d6d6d;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .frame-74 {
    align-items: center;
    background-color: #4ba5eb;
    border-radius: 0px 4px 4px 0px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 8px 10px;
    position: relative;
  }
  
  .landing-page-revised .text-wrapper-66 {
    color: #ffffff;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .landing-page-revised .frame-75 {
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, rgb(0, 6.5, 54.19) 0%, rgba(0, 3, 25, 0) 100%);
    display: flex;
    gap: 35.62px;
    height: 268px;
    justify-content: center;
    left: 0;
    padding: 96px 73px;
    position: absolute;
    top: 940px;
    width: 1440px;
  }
  
  .landing-page-revised .image {
    height: 30.44px;
    margin-left: -18.3px;
    object-fit: cover;
    position: relative;
    width: 30.44px;
  }
  
  .landing-page-revised .image-2 {
    height: 30.44px;
    object-fit: cover;
    position: relative;
    width: 30.44px;
  }
  
  .landing-page-revised .image-3 {
    height: 42.09px;
    object-fit: cover;
    position: relative;
    width: 36.26px;
  }
  
  .landing-page-revised .download {
    height: 42.09px;
    object-fit: cover;
    position: relative;
    width: 37.56px;
  }
  
  .landing-page-revised .image-4 {
    height: 46.63px;
    object-fit: cover;
    position: relative;
    width: 46.63px;
  }
  
  .landing-page-revised .image-5 {
    height: 50px;
    object-fit: cover;
    position: relative;
    width: 76.07px;
  }
  
  .landing-page-revised .image-6 {
    height: 50px;
    object-fit: cover;
    position: relative;
    width: 38.03px;
  }
  
  .landing-page-revised .image-7 {
    height: 41.45px;
    object-fit: cover;
    position: relative;
    width: 30.44px;
  }
  
  .landing-page-revised .element {
    height: 49.86px;
    object-fit: cover;
    position: relative;
    width: 49.86px;
  }
  
  .landing-page-revised .image-8 {
    height: 50px;
    object-fit: cover;
    position: relative;
    width: 50.85px;
  }
  
  .landing-page-revised .pngaaa {
    height: 47.92px;
    object-fit: cover;
    position: relative;
    width: 30.44px;
  }
  
  .landing-page-revised .image-9 {
    height: 48.57px;
    object-fit: cover;
    position: relative;
    width: 53.1px;
  }
  
  .landing-page-revised .pngwing {
    height: 49.86px;
    object-fit: cover;
    position: relative;
    width: 73.18px;
  }
  
  .landing-page-revised .image-10 {
    height: 49.86px;
    margin-right: -18.3px;
    object-fit: cover;
    position: relative;
    width: 198.81px;
  }