.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Make the container fill the entire viewport height */
  }
  
  .not-found-container h1 {
    font-size: 24px;
  }
  
  .not-found-container p {
    font-size: 16px;
    margin-top: 10px;
  }
  